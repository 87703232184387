.productPage_h1block
	margin-bottom: 30px
.productPage_select
		display: flex
		justify-content: center
		margin-bottom: 30px
.productPage_selectButton
	padding: 6px 15px
	background: rgba(#fff, .4 )
	border: 2px solid #846614
	margin: 0 6px
	border-radius: 12px
	&.active
		background: #fff
.productPage_h1block_h1
	color: #fff
	font-size: 75px
	font-weight: 700
	line-height: 2
	span
		display: block
.h1_1
	position: relative
	text-align: right
	left: -100vw
.h1_2
	position: relative
	right: -100vw
	text-align: center
.h1_3
	position: relative
	text-align: right
	left: -100vw
.row
	flex-wrap: nowrap
	position: relative
.h1_pic
	width: 480px
	min-width: 480px
.h1block_descr
	display: flex
	margin: auto 0 0
	gap: 40px
	justify-content: flex-end
	margin-bottom: 60px
.h1block_content
	position: absolute
	width: 100%
	height: 100%
	right: 0
	top: 0
	display: flex
	flex-direction: column
.h1block_item
	background: #fff
	width: 240px
	min-width: 240px
	border-radius: 24px
	overflow: hidden
.h1block_ttl
	padding: 15px 25px
	text-align: center
	font-family: Merriweather
	line-height: 1.27
.h1block_img
	position: relative
.icon
	position: absolute
	right: 25px
	top: 182px
	

