.footer 
	padding: 30px 0
	.row
		margin-left: -15px
		margin-right: -15px
		display: flex
		flex-wrap: nowrap
.footer_item
	flex-grow: 1
	color: #fff
	p
		font-size: 20px
		font-weight: 700
		color: #fff
		max-width: 320px
		line-height: 1.4
	li
		font-size: 22px
		a
			color: #fff
			padding: 6px 15px
		&:first-child
			a
				color: #ffff38
	button
		width: 320px
		background: rgba(#fff, .4 )
		color: #69332b
		font-size: 16px
		margin: 12px 0
.bottom
	.row
		align-items: center
	p
		color: #ffff38
		font-size: 14px
		font-weight: 500
