.row 
	margin-left: -15px
	margin-right: -15px
	display: flex
	flex-wrap: wrap

.container 
	margin-right: auto
	margin-left: auto
	padding-left: 15px
	padding-right: 15px
	position: relative

.col 
	padding-left: 15px
	padding-right: 15px

@media (min-width:767px) 
	.container 
		width: 750px
	


@media (min-width:992px) 
	.container 
		width: 970px
	


// @media (min-width:1230px) 
// 	.container 
// 		width: 1200px
	



// @media  (min-width: 2000px) 
// 	.container-fluid 
// 		max-width: 1700px
// 		margin: 0 auto
	




.container-fluid 
	margin-right: auto
	margin-left: auto
	padding-left: 15px
	padding-right: 15px

@font-face 
	font-family: "Manrope"
	font-weight: 400
	font-display: swap
	src: url("./fonts/Manrope/Manrope-Regular.woff2")  format('woff2')
@font-face 
	font-family: "Manrope"
	font-weight: 500
	font-display: swap
	src: url("./fonts/Manrope/Manrope-Medium.woff2")  format('woff2')
@font-face 
	font-family: "Manrope"
	font-weight: 600
	font-display: swap
	src: url("./fonts/Manrope/Manrope-SemiBold.woff2")  format('woff2')
@font-face 
	font-family: "Merriweather"
	font-weight: 400
	font-display: swap
	src: url("./fonts/Merriweather-Regular.woff2")  format('woff2')

$a: #42623B
$cd: #212121
$ma: "Manrope"
$me: "Merriweather"
.red
	color: #FF1111

html, body
	height: 100%
body
	min-width: 320px
	position: relative
	line-height: 1.5
	background: #9bd28f
	font-family: "Manrope"
	overflow-x: hidden
	color: $cd
	font-size: 18px
	font-feature-settings: 'pnum' on, 'lnum' on
.wrap-items
	display: flex
	flex-wrap: wrap
	width: 100%
.flex
	display: flex
.s-section
	padding: 40px 0
.section-wrap
	width: 100%
.button
	display: flex
	align-items: center
	justify-content: center
	cursor: pointer
	min-width: 160px
	height: 54px
	background: $a
	border-radius: 50px
	color: #fff
	font-size: 16px
	font-weight: 500
	padding: 0 20px
	border: 2px solid transparent
	&.button--white
		background: #fff
		color: $a
		border: 2px solid #9BD28F
	&.button--red
		background: #fff
		color: #FF1111
		border: 2px solid #FF11121A
	&.button--yellow
		background: transparent
		color: #FFFF38
		border: 2px solid #FFFF7C
	svg
		margin-left: 10px
.header
	position: absolute
	left: 0
	top: 0
	background: transparent
	z-index: 10
	width: 100%
	padding: 6px 0

	.row
		justify-content: space-between
		align-items: center
		flex-wrap: nowrap
	.header_part
		display: flex
	.header_icon
		width: 44px
		height: 44px
		display: flex
		align-items: center
		justify-content: center
		border-radius: 50%
		background: #B4CEAE
		cursor: pointer
		margin-left: 12px
	.h1
		flex-grow: 1
		text-align: center
		margin-right: 40px
		font-family: $me
		font-size: 20px
		color: #69332b
	.mnu-col
		position: absolute
		right: 0
		top: 0
.mnu
	position: absolute
	width: 100%
	height: 100vh
	right: 0
	top: 0
	overflow-y: auto
	.close
		position: absolute
		right: 15px
		top: 15px
	.wnu_wrap
		background: rgba($a, 70% )
		width: 100%
		height: 100%
		box-shadow: 4px -10px 30px 0px #42623B1F
		display: flex
		justify-content: flex-end
	.wnu_content
		width: 80%
		height: 100vh
		overflow: auto
		background: #fff
		padding: 80px 20px 20px
		display: flex
		flex-direction: column
		.mnu_list
			flex-grow: 1
		.mnu_link1
			font-weight: 600
		li
			padding: 5px 0
			font-size: 22px
			font-weight: 500
			color: $a
	.ssoc
		a
			margin-right: 10px
			padding-top: 10px



.s-home
	padding-bottom: 130px
	
	position: relative
	.s-home-bg1
		position: absolute
		left: 0
		top: 0
		width: 100%
		height: 50%
		background: url("/img/bg.png") no-repeat 
		background-size: cover
	.s-home-bg2
		position: absolute
		left: 0
		bottom: 0
		width: 100%
		height: 50%
		background: url("/img/bg2.png")  50% 50%
		background-size: 100% 100%
		&::before
			content: ''
			width: 100%
			height: 100%
			position: absolute
			left: 0
			bottom: 0
			background: url("/img/b2.png") no-repeat 50% 50%
			background-size: cover
			
.product
	max-width: 330px
	
	//overflow: hidden
	margin: 90px auto 0
	position: relative
	.product_wrap
		background: #fff
		border-radius: 20px
		overflow: hidden
		position: relative
	.product_ic
		position: absolute
		left: 20px
		top: -70px
	.product_ic-2
		left: auto
		right: 15px
	.product_info
		padding: 15px 15px 15px
	.product_pic
		height: 184px
		img
			width: 100%
			height: 100%
			max-width: 1000%
			object-fit: cover
	.product_nm
		font-family: $me
		font-size: 24px
		color: $a
	.product_row
		display: flex
		flex-wrap: wrap
	.product_composition
		margin: 8px 0
		width: 50%
		display: flex
		align-items: center
		&:nth-child(odd)
			padding-right: 10px
	.product_composition-pic
		margin-right: 8px
	.product_price
		flex-grow: 1
		font-weight: 600
		font-size: 24px
		color: $a
		padding-left: 15px
		text-align: right
		.act
			text-decoration: line-through
			margin-right: 10px
	.product_buttons
		margin-top: 10px
		padding: 10px 0
		border-top: 1px solid #D4D4D4
		justify-content: space-between
		align-items: center
		flex-wrap: nowrap
		input
			width: 140px
			text-align: center
			border: none
	.product_buttons-ic
		width: 40px
		height: 40px
		border-radius: 50%
		display: flex
		align-items: center
		justify-content: center
		cursor: pointer
	.product_buttons-min
		border: 1px solid #CBCBCB
		color: #CBCBCB
	.product_buttons-plus
		background: #9BD28F
	.product_buttons-count
		color: #112a0b
		font-size: 32px
		line-height: 1
		font-weight: 500
		&.disabled
			color: #98979a
	.product_text
		font-size: 14px
		text-align: center
		color: $a
	.product_text-prev
		display: flex
		justify-content: center
		align-items: center
		svg
			cursor: pointer
			margin-left: 5px
.cartBottomPart
	position: fixed
	padding: 15px
	width: 100%
	min-height: 50px
	left: 0
	bottom: 0
	background: #fff
	.row
		justify-content: space-between
	.cartBottomPart_elem-text
		font-size: 14px
		font-weight: 500
		color: $a
		line-height: 1
	.cartBottomPart_elem-price
		font-size: 26px
		font-weight: 500
		color: $a
.checoutPage
	padding-top: 80px
	.header
		padding: 15px 0
.checkoutBlocks
	padding-bottom: 25px
.checkoutBlock
	background: #fff
	padding: 15px
	border-radius: 20px
	margin-bottom: 20px

	.checkoutBlock_h2
		display: flex
		align-items: flex-start
		justify-content: space-between
		color: $a
		font-size: 24px
		font-family: $me	
		.pencil
			min-width: 36px
			cursor: pointer
			height: 36px
			border-radius: 50%
			border: 1px solid #CBCBCB
			display: flex
			align-items: center
			justify-content: center
	.checkoutBlock_h2--mb
		margin-bottom: 15px
	.checkoutBlock_np
		align-items: center
	.checkoutBlock_np--mt
		margin-top: 15px
		padding-top: 15px
		border-top: 1px solid #D4D4D4
	.checkoutBlock_ttl
		font-size: 20px
		margin-left: 10px
		font-weight: 500
	.checkoutBlock_price
		flex-grow: 1
		text-align: right
		font-size: 20px
		color: #98979a
	.radio
		margin: 15px 0
	.checkoutBlock_grey
		color: #98979a
		line-height: 1.22
		margin: 15px 0 10px
	
	.checkoutBlock_list
		color: #98979A
		font-size: 16px
		p
			padding-left: 15px
			position: relative
			line-height: 1.3
			&::before
				content: ''
				position: absolute
				left: 0
				top: 6px
				width: 5px
				height: 5px
				border-radius: 50%
				background: #98979A
	.checkoutBlock_img
		margin-bottom: 10px
input::-webkit-input-placeholder
	color: #98979A

input::-moz-placeholder
	color: #98979A

input:-moz-placeholder
	color: #98979A

input:-ms-input-placeholder
	color: #98979A
.dropdown
	position: relative

	.dropdown_list
		font-size: 16px
		padding: 15px 0
		width: calc(100% + 30px)
		max-height: 300px
		overflow-y: auto
		position: absolute
		background: #fff
		left: -15px
		bottom: 60px
		li
			border-bottom: 1px solid #D4D4D4
			margin: 0 15px
			padding: 5px 15px
			cursor: pointer
			&:hover
				background: #ccc

.label
	display: block
	margin-top: 10px
	&.error
		input
			border: 2px solid #FF1111 !important
	p.error
		color: #f11
		font-size: 16px
	.label_ttl
		margin-bottom: 5px
		position: relative
		.oldPass
			position: absolute
			right: 0
			top: 2px
			font-size: 16px
			color: $a
	&.radio
		display: flex
		padding-left: 30px
		position: relative
		.label_ttl
			margin-bottom: 0
		input
			display: none
		&::before
			content: ''
			position: absolute
			width: 20px
			height: 20px
			border-radius: 50%
			border: 2px solid #D2D4DF
			left: 0
			top: calc(50% - 10px)
		&.active
			&::before
				border: 6px solid $a
	input[type=text], input[type=password]
		height: 58px
		border-radius: 20px
		border: 2px solid transparent
		font-size: 16px
		display: block
		width: 100%
		text-indent: 16px
		background: #D4D4D433
		border: none

.product-checkout
	display: flex
	padding: 12px 0
	border-bottom: 1px solid #D4D4D4
	&.product-checkout--small
		.product-checkout_img
			width: 32px
			height: 32px
		.product-checkout_info
			display: flex
			flex-grow: 1
		.product-checkout_count
			flex-grow: 1
			text-align: right
	&:last-child
		border-bottom: none
		padding-bottom: 0
	.product-checkout_img
		position: relative	
		width: 64px
		height: 64px
		border-radius: 8px
		overflow: hidden
		img
			width: 100%
			max-width: 1000%
			height: 100%
			object-fit: cover
	.product-checkout_info
		margin-left: 10px
	.product-checkout_ttl
		font-size: 18px
		font-weight: 500
		color: #212121
	.product-checkout_count
		color: $a
		font-size: 18px
		font-weight: 500
		.grey
			color: #ccc

.checkoutRes
	background: #fff
	padding: 15px 25px 25px
	.checkoutRes_row
		display: flex
		color: #98979A
		font-weight: 500
		margin: 10px 0
		&:first-child
			color: $a
	.checkoutRes_nm
		flex-grow: 1
	.checkoutRes_error
		color: #f11
		font-weight: 500
		text-align: center
		background: #ffe1e1
		border-radius: 20px
		padding: 9px 15px
		.checkoutRes_error-pic
			margin-bottom: 5px
			img
				margin: auto
.info
	display: flex
	padding: 8px 15px
	font-weight: 500
	border: 1px solid #D4D4D4
	border-radius: 20px

	.info_ic
		min-width: 24px
		margin-right: 12px
	.info_row
		display: flex
		margin: 10px 0
		font-size: 16px
	.info_nm
		flex-grow: 1
		color: $a
	.info_price
		color: #98979a

.checkout-total
	text-align: center
	padding-top: 8px
	margin: 10px 0	
	border-top: 1px solid #D4D4D4
	.checkout-total_ttl
		color: $a
		font-weight: 500
		text-align: center
	.checkout-total_res
		color: $a
		font-size: 26px
		font-weight: 500
		text-align: center
	.button
		width: 100%
		margin: 10px 0
	.checkout-total_check
		font-size: 16px
		color: #98979a
.wrapPage
	padding-top: 80px
.wrapper
	text-align: center
	&.wrapper--tal
		text-align: left
		padding-bottom: 20px
	.wrapper_big
		font-size: 40px
		color: $a
	.wrapper_wrap
		margin: 0 15px
		padding: 15px 15px 20px
		background: #fff
		border-radius: 20px
		&.ta
			text-align: left	
	.wrapper_ttl
		font-size: 20px
	.wrapper_descr
		color: $a
		padding: 5px 15px
	.wrapper_img
		img
			margin: auto
	.wrapper_info
		color: $a
		font-weight: 500
	.button
		width: 100%
		margin-top: 15px

.orderInfo
	margin: 20px 15px !important
	&:first-child
		margin-top: 0 !important
	.orderInfo_ttl
		font-weight: 600
		color: $a
	&.orderInfo_ttl--500
		font-weight: 500
	.orderInfo_descr
		color: #98979a
		flex-grow: 1
		text-align: right
	&.orderInfo_descr-bold
		font-weight: 600
	.orderInfo_num
		color: #98979a
		font-weight: 500
		margin: 5px 0 10px
	.orderInfo_items
		background: #ecf4ea
		padding: 8px
		border-radius: 20px
	.orderInfo_pay
		padding: 12px 0
		border-bottom: 1px solid #D4D4D4
	.button-block
		display: flex
		align-items: center
	.orderInfo_mnu
		display: flex
		align-items: center
		justify-content: center
		cursor: pointer
		margin-top: 15px
		margin-left: 12px
		min-width: 54px
		height: 54px
		border-radius: 50%
		border: 1px solid #CACACA
.profile
	padding-bottom: 20px
	.wrapper_wrap
		margin-bottom: 20px
	.profile_block
		margin-bottom: 20px
	.profile_details-one
		display: flex
		justify-content: center
		align-items: center
		font-size: 14px
		color: $a
		border-top: 1px solid #D4D4D4
		padding-top: 10px
		img
			margin-left: 5px
	.profile_details
		cursor: pointer

.details
	.details_grey
		background: #ECF4EA
		border-radius: 16px
		font-size: 16px
		font-weight: 500
		padding: 15px
		margin: 12px 0
	.details_big
		font-size: 40px
		color: $a
		font-family: $me
		font-weight: 400
	.details_white
		font-size: 16px
		border-radius: 16px
		padding: 15px
		border: 1px solid #D4D4D4
	.details_img
		margin: 12px 0
		img
			margin: auto
	.details_sales
	.details_row
		display: flex
		font-weight: 500
		margin: 12px 0
	.details_ttl
		p
			display: flex
			align-items: center
			img
				margin-right: 10px
	.details_descr
		flex-grow: 1
		text-align: right
	.details_bold
		font-size: 17px
		font-weight: 600
		text-align: center
		flex-grow: 1
.lay
	.container
		height: 100%
	&.active
		position: fixed
		bottom: 0
		left: 0
		width: 100%
		height: 100vh
		background: rgba(#000,.6 )
		transition: all .5s ease-in-out
		z-index: 999
	.lay_wrap
		height: 0
		background: #fff
		position: fixed
		width: 100%
		left: 0
		bottom: 0
		padding: 15px 0
		border-top-right-radius: 16px
		border-top-left-radius: 16px
		transition: all .5s 
		//animation: vv .5s 
		&-enter
			height: 0

		&-enter-active
			height: 80vh  // Установите максимальную высоту для плавного раскрытия

		&-exit
			height: 80vh  // Установите максимальную высоту
		&-enter-done
			height: 80vh  // Установите максимальную высоту

		&-exit-active
			height: 0
		&-exit-active
			height: 0
			transition: all .5s 
		&.fullHeight 
			border-radius: 0
			overflow-y: auto
			&-enter
				height: 0
			&-enter-active
				height: 100vh  // Установите максимальную высоту для плавного раскрытия

			&-exit
				height: 100vh  // Установите максимальную высоту
			&-enter-done
				height: 100vh  // Установите максимальную высоту
			&-exit-active
				height: 0
			&-exit-active
				height: 0
				transition: all .5s 
		&.email
			.lay_text
				text-align: left
				font-weight: 500
				margin: 10px 0

	.lay_img
		margin: 10px 0
		img
			margin: auto
	.lay_ttl
		p
			position: relative
			text-align: center
			color: $a
			font-weight: 500
		.close
			position: absolute
			right: 0
			top: 5px
			cursor: pointer
	.button-block
		text-align: center
		p
			font-size: 16px
	.button
		width: 100%
		margin: 15px 0
@keyframes vv 
	0% 
		height: 0
	100%	
		height: 80vh
.deleteAkk, .flexLay
	display: flex
	flex-direction: column
	height: 100%
	.button-block
		margin: auto 0 0
	.lay_text
		text-align: center

.details
	// overflow: hidden
	max-height: 2000px
	transition: all .5s ease-in-out

	&-enter
		max-height: 0

	&-enter-active
		max-height: 2000px  // Установите максимальную высоту для плавного раскрытия

	&-exit
		max-height: 2000px  // Установите максимальную высоту

	&-exit-active
		max-height: 0
.productPage
	padding-bottom: 0
	font-family: Montserrat
	&.productPage--yellow
		background: linear-gradient(to bottom, #FCD365, #F4950C)
		.header_icon
			background: rgba(#fff, .4 )
	.productPage_select
		display: flex
		justify-content: center
		margin-bottom: 30px
	.productPage_select-button
		padding: 6px 15px
		background: rgba(#fff, .4 )
		border: 2px solid #846614
		margin: 0 6px
		border-radius: 12px
		&.active
			background: #fff
.adventages
	.adventages_row
		display: flex
		justify-content: center
		margin: 25px 0
	.adventages_item
		text-align: center
		color: #FFFF38
		&.adventages_item--big
			width: 200px
			min-width: 200px
			margin: 0 40px
		&.adventages_item--small
			width: 184px
			min-width: 184px
	.adventages_item-img
		img
			margin: auto
	.adventages_item-ttl
		font-family: $me
		font-size: 26px
		text-transform: uppercase
		margin: 10px 0
	.adventages_item-descr
		font-weight: 500
	.button-block
		width: 100%
		margin-top: 50px
		.button
			margin: auto
			min-width: 320px

