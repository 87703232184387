.adventages
	.adventages_row
		display: flex
		justify-content: center
		margin: 25px 0
	.adventages_item
		text-align: center
		color: #FFFF38
		&.adventages_itemBig
			width: 200px
			min-width: 200px
			margin: 0 40px
		&.adventages_itemSmall
			width: 184px
			min-width: 184px
	.adventages_itemImg
		img
			margin: auto
	.adventages_itemTtl
		font-family: Merriweather
		font-size: 26px
		text-transform: uppercase
		margin: 10px 0
	.adventages_itemDescr
		font-weight: 500
	.buttonBlock
		width: 100%
		margin-top: 50px
		.button
			margin: auto
			min-width: 320px
