*
	margin: 0
	padding: 0
	box-sizing: border-box
	&:before, &:after
		box-sizing: border-box  
		
table
	border-collapse: collapse
	border-spacing: 0
ul,
li
	list-style-type: none

h1,h2,h3,h4,h5,h6
	font-weight: normal

sup
	vertical-align: text-top

sub
	vertical-align: text-bottom

input,textarea,select
	font-family: inherit
	font-size: inherit
	font-weight: inherit
	max-width: 100%



article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section,main
	display: block

img
	height: auto
	max-width: 100%
	border: 0
	display: block

a
	display: inline-block
	transition: all 0.3s
	text-decoration: none
	color: #42623B

a:active, a:focus, .slick-slide, a, button, .button, input, textarea, select
	outline: none

strong
	font-weight: bold

form
	label
		display: block
		span
			display: block


